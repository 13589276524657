body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Palanquin', sans-serif;
  font-weight: 300;
  background-color: #faf8f2;
  background-image: url("images/background.png");
  background-repeat: repeat;
  overflow-y: hidden;
  /* letter-spacing: .1px; */
  /* font-family: 'Teko', sans-serif; */
  /* padding-top: 7px; */
  color: rgb(20, 20, 20);
  /*  margin: auto; */
  /* height: 100%; */
}
/* NEWER c43e29 */
/* NEWER c33f28 */
/* NEW c83f28 */
/* OLD b93e2b */
:root {
  --main-red: #b93e2b;
}

.quoteChange {
  font-family: sans-serif;
}


.sidenav {
  background-image: url("images/background.png");
  background-repeat: repeat;
}

.appContainerLarge{
  display: grid;
  grid-template-columns: 30vw 70vw;
  grid-template-rows: 100vh;
}

.appContainerMedium{
  display: grid;
  grid-template-columns: 30vw 70vw;
  grid-template-rows: 100vh;
}

.appContainerSmall{
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 100vh;
}

.navContainerLarge {
  grid-column: 1 / 1;
  text-align: right;
  position: relative;
}

.navContainerMedium {
  grid-column: 1 / 1;
  text-align: right;
  /* padding-left: 15%; */
  /* position: relative; */
  /* white-space: nowrap; */
}

.navContainerSmall {
  display: none;
}

.contentContainerSmall {
  grid-column: 1 / 1;
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.contentContainerMedium {
  grid-column: 2 / 2;
  overflow-y: scroll;
  padding-right: 7%;
  padding-left: 26px;
  padding-top: 10px;
}

.contentContainerLarge {
  grid-column: 2 / 2;
  overflow-y: scroll;
  padding-right: 5%;
  padding-left: 32px;
  padding-top: 12px;
}

.tempTurnOffScroll {
  overflow-y: hidden;
}

.frontImg {
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  margin-top: 6px;
}

.para {
  width: 100%;
  max-width: 850px;
}

.nowPara {
  width: 100%;
  max-width: 800px;
  /* margin-left: 14px; */
  margin-top: 18px;
  margin-bottom: 18px;
}



.regularScroll {
  overflow-y: visible;
  /* overflow-x: hidden; */
}


.navButton {
  height: 50px;
  background-color: var(--main-red);
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 4px;
  padding-bottom: 5px;
  letter-spacing: 1.3px;
  word-spacing: 3px;
  width: 75%;
  margin: 5px;
  text-transform: uppercase;
  min-width: 135px;
  max-width: 165px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 12pt;
  /* float: right; */
}

.unread{
  color: rgb(255, 255, 155);
}

.searchButton {
  height: 35px;
  background-color: var(--main-red);
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 500;
  padding: 8px 12px 10px 12px;
  letter-spacing: 0.4px;
  word-spacing: 1px;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 10.5pt;
}

.nextBackButton {
  margin: 0px;
}

.accountButton {
  height: 40px;
  background-color: var(--main-red);
  color: white;
  border-radius: 5px;
  border: 0px;
  width: 220px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 500;
  padding: 10px 16px 11px 16px;
  letter-spacing: 0.2px;
  word-spacing: 1px;
  margin-bottom: 8px;
  margin-top: 8px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 11pt;
}

.accountButton:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.flagButton:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}



.nowPostButton {
  height: 35px;
  background-color: #0c3963;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 500;
  padding: 8px 14px 10px 14px;
  letter-spacing: 0.4px;
  word-spacing: 1px;
  /* margin-bottom: 6px; */
  /* margin-left: 16px; */
  /* margin-right: 10px; */
  /* margin-top: 7px; */
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 10.5pt;
}

.editDeletePostButton {
  /* margin-bottom: 10px; */
  margin-top: 10px;
  padding: 8px 16px 10px 16px;
}


.sendMessageButtonWait {
  max-width: 200px;
  width: 185px;
  margin: 0px;
  margin-left: -4px;
  margin-top: 14px;
  background-color: var(--main-red);
}

.sendMessageButtonWait {
  max-width: 200;
  width: 200px;
  margin: 0px;
  margin-left: -4px;
  margin-top: 14px;
  background-color: var(--main-red);
  font-size: 11pt;
}

.chatSend {
  width: 100%;
  max-width: 70px;
  min-width: 50px;
  background-color: var(--main-red);
  height: 35px;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 4px;
  padding-bottom: 5px;
  letter-spacing: 1.3px;
  word-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 10pt;
  display: inline-block;
  /* margin-left: 8px; */
  margin-bottom: 13px;
  position: relative;
  bottom: 5px;
}

.chatFlag {
  width: 100%;
  max-width: 70px;
  min-width: 50px;
  background-color: #ef6c00;
  height: 35px;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 4px;
  padding-bottom: 5px;
  letter-spacing: 1.3px;
  word-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 10pt;
  /* display: inline-block; */
  /* margin-left: 8px; */
  
  position: relative;
  bottom: 5px;
}

.chatSend:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.chatFlag:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.chatSend:hover:enabled {
  background-color: #d64c36;
  color: white;
}

.chatFlag:hover:enabled {
  background-color: #ff8e32;
  color: white;
}

.chatSend:focus:enabled{
  background-color: var(--main-red);
}

.chatSend:focus:hover:enabled{
  background-color: #d64c36;
} 

.chatFlag:focus:enabled{
  background-color: #ef6c00;
}

.chatFlag:focus:hover:enabled{
  background-color: #ff8e32;
} 

.searchButton:hover:enabled {
  background-color: #d64c36;
  color: white;
}

.searchButton:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.searchButton:focus:enabled{
  background-color: var(--main-red);
}

.searchButton:focus:hover:enabled{
  background-color: #d64c36;
} 

.accountButton:hover:enabled {
  background-color: #d64c36;
  color: white;
}

.accountButton:focus:enabled{
  background-color: var(--main-red);
}

.accountButton:focus:hover:enabled{
  background-color: #d64c36;
} 



.navSmallButton {
  height: 40px;
  background-color: var(--main-red);
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 4px;
  padding-bottom: 5px;
  letter-spacing: 1.3px;
  word-spacing: 3px;
  margin: 5px;
  text-transform: uppercase;
  width: 122px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 11pt;
  margin-right: 40px;
}

.getStarted {
  height: 46px;
  background-color: var(--main-red);
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 4px;
  padding-bottom: 5px;
  letter-spacing: 1.3px;
  word-spacing: 3px;
  /* text-transform: uppercase; */
  width: 150px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 13pt;
}

.subNav {
  clear: both;
}

.subNavSmall {
  text-align: right;
  /* float: right */
}




.navButton:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.navButton:hover:enabled {
  background-color: #d64c36;
  color: white;
}



.getStarted:hover:enabled {
  background-color: #d64c36;
  color: white;
}

.getStarted:focus:enabled{
  background-color: #c2422f;
}

.getStarted:focus:hover:enabled{
  background-color: #d64c36;
}


.navButton:focus:enabled{
  background-color: #c2422f;
}

.navButton:focus:hover:enabled{
  background-color: #d64c36;
}


.navSmallButton:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.navSmallButton:hover:enabled {
  background-color: #d64c36;
  color: white;
}

.navSmallButton:focus:enabled{
  background-color: #c2422f;
}

.navSmallButton:focus:hover:enabled{
  background-color: #d64c36;
}


.now {
  background-color: #0c3963;
}

.now:hover:enabled {
  background-color: #185186;
}

.now:focus:enabled{
  background-color: #0c3963;
}

.now:focus:hover:enabled{
  background-color: #185186;
}

.nowSelectButton:hover:enabled {
  background-color: #185186;
}

.nowSelectButton:focus:enabled{
  background-color: #0c3963;
}

.nowSelectButton:focus:hover:enabled{
  background-color: #185186;
}

.nowPostButton:hover:enabled {
  background-color: #185186;
}

.nowPostButton:focus:enabled{
  background-color: #0c3963;
}

.nowPostButton:focus:hover:enabled{
  background-color: #185186;
}

.nowPostButton:disabled{
  background-color: #cfcfcf;
  box-shadow: none;
  cursor: default;
}

.flagButton {
  max-width: 200px;
  min-width: 10px;
  margin: 0px;
  margin-left: -4px;
  margin-top: 11px;
  width: 90px;
  height: 36px;
  background-color: #ef6c00;
  font-size: 11pt;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  font-family: 'Palanquin', sans-serif;
  border: 0px;
  padding: 4px;
  padding-bottom: 5px;
  letter-spacing: 1.3px;
  word-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
}

.flagButton:hover:enabled {
  background-color: #ff8e32;
  color: white;
}

.flagButton:focus:enabled{
  background-color: #ef6c00;
}

.flagButton:focus:hover:enabled{
  background-color: #ff8e32;
}


.navLogo {
  margin-top: 10px;
  margin-bottom: 16px;
  max-width: 260px;
  min-width: 150px;
  /* float: right; */
  /* margin-left: 20px; */
  margin-right: 2px;
  /* clear: right; */
  
}

.navLogoSmall {
  max-width: 180px;
  margin-top: 15px;
  margin-bottom: 13px;
  
}

.activity {
  position: relative;
}

/* .menuIcon {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 46px;
  cursor: pointer;
  z-index: 501;
}

.menuIconWithMail {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  cursor: pointer;
  z-index: 501;
} */

#menuIcon {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 46px;
  cursor: pointer;
  z-index: 501;
}


/* .rightPadding{
  padding-right: 40px;
} */



.captcha {
  border-radius: 5px;
  width: 160px;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  margin-top: 32px;
  margin-bottom: 12px;
}

input, textarea {
  max-width: 545px;
  width: 95%;
  padding: 9px;
  padding-top: 7px;
  padding-left: 14px;
  padding-right: 14px;
  margin: 10px;
  margin-left: 7px;
  font-size: 14pt;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  border-color: grey;
  font-family: 'Palanquin', sans-serif;
  letter-spacing: .5px;
  box-shadow: 2px 2px 5px lightgrey;
  background-color: white;
  line-height: normal;
  font-weight: 400;
}

.donateButton {
  max-width: 300px;
  width: 120px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
  margin-left: 0px;
  margin-top: 8px;
  font-size: 12.5pt;
  border-radius: 0px;
  border-style: none;
  border-width: thin;
  border-color: grey;
  font-family: 'Palanquin', sans-serif;
  letter-spacing: .5px;
  box-shadow: 0px 0px 0px lightgrey;
  background-color: transparent;
  line-height: normal;
  font-weight: 600;
  cursor: pointer;
  color: var(--main-red);
}

.aboutYourself {
  height: 330px;
}

.changeEmailPasswordVerifyFailure {
  width: 100%;
  margin: 0px 0px 0px 0px;
  font-size: 13pt;
  padding: 6px;
  padding-top: 4px;
  padding-left: 11px;
  padding-right: 11px;
  max-width: 500px;
}

.forgotEmailInput {
  max-width: 400px;
  width: 100%;
  margin: 0px;
  font-size: 13pt;
  padding: 6px;
  padding-top: 4px;
  padding-left: 11px;
  padding-right: 11px;
}

.postSubject {
  max-width: 100%;
  width: 100%;
  padding: 9px;
  padding-top: 7px;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0px;
  margin-left: 0px;
  font-size: 14pt;
  border-radius: 5px;
  font-family: 'Palanquin', sans-serif;
  box-shadow: 0px 0px 0px lightgrey;
  background-color: white;
  line-height: normal;
  font-weight: 400;
  height: 20%;
  max-height: 55px;
  min-height: 45px;
  
}

.postTextArea {
  max-width: 2000px;
  width: 100%;
  margin: 0px;
  margin-top: 5px;
  height: 82%;
  box-shadow: 0px 0px 0px lightgrey;
}

textarea {
  height: 120px;
  line-height: 28px;
}

.messageTextArea {
  max-width: 2000px;
  width: 100%;
  margin: 0px;
  margin-top: 5px;
  height: 85%;
  /* margin: auto; */
}

.flagTextArea {
  max-width: 2000px;
  width: 95%;
  margin: 0px;
  margin-bottom: 8px;
  margin-top: 8px;
  /* margin-top: 5px; */
  height: 100px;
  /* margin: auto; */
}

.chatTextArea {
  margin: 0px;
  margin-top: 0px;
  height: 100%;
  resize: horizontal;
  line-height: 25px;
  /* margin-left: -10px; */
}


.chatSendContainer {
  height: 140px;
  max-width: 700px;
  /* margin-bottom: 26px; */
  /* background-color: lightblue; */
  margin-bottom: 24px;


}

.chatTextContainer {
  display: inline-block;
  width: 70%;
  /* background-color: salmon; */
  height: 100%;
}

.chatButtonContainer {
  display: inline-block;
  width: 25%;
}

.editNameInput {
  margin-left: 0px;
  margin-bottom: 16px;
  margin-top: 8px;
  margin-right: 0px;
  width: 80%;
  max-width: 500px;
}

.editAboutInput {
  margin-left: 0px;
  margin-bottom: 18px;
  margin-top: 8px;
  margin-right: 0px;
  width: 80%;
  max-width: 500px;
  height: 330px;
}

.signinInput{
  width: 93%;
  /* margin-left: 50px; */
  margin-left: 10px;
  max-width: 405px;
}



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: .55; /* Firefox */
  font-weight: 500;
  /* line-height: 0px; */
}


.submitDiv {
  text-align: right;
  max-width: 545px;
  width: 95%;
  margin-left: 10px;
  padding: 10px 12px;
  border-radius: 5px;
}

.signinSubmitDiv {
  text-align: right;
  max-width: 405px;
  width: 93%;
  margin-left: 15px;
  padding: 10px 12px;
  border-radius: 5px;
}

.submitSignup{
  margin-right: -10px;
  width: 150px;

}

.resendSignin{
  margin-right: -10px;
  width: 200px;
  max-width: 200px;
  margin-top: 16px;
}

.largerText {
  font-size: 15pt;
}


.heading{
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  margin: 9px;
  margin-top: 28px;
  margin-bottom: 16px;
  letter-spacing: 2px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 30pt;
}

.heading2{
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  margin: 12px;
  margin-top: 28px;
  margin-bottom: 24px;
  letter-spacing: 2px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 30pt;
}

.modalHeading{
  /* text-shadow: 3px 3px 5px lightgrey; */
  letter-spacing: 2px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
}



.uploadImage{
  height: 47px;
  background-color: var(--main-red);
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 10px;
  letter-spacing: 1px;
  text-align: center;
  width: 185px;
  /* text-transform: uppercase; */
  /* min-width: 120px; */
  /* max-width: 165px; */
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 12pt;
  display: block;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.uploadImagePleaseWait {
  height: 47px;
  background-color: #cfcfcf;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  padding: 10px;
  letter-spacing: 1px;
  text-align: center;
  width: 185px;
  /* text-transform: uppercase; */
  /* min-width: 120px; */
  /* max-width: 165px; */
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 12pt;
  display: block;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;

}

.uploadPicDiv {
  text-align: center;
  max-width: 545px;
  width: 95%;
  /* margin-left: 10px; */
  padding: 20px;
  border-radius: 5px;
  border: 1px;
  border-style: solid;
  border-width: thin;
  border-color: grey;
  font-family: 'Palanquin', sans-serif;
  font-weight: 300;
  letter-spacing: .5px;
  box-shadow: 1px 1px 3px lightgrey;
  background-color: white;
  height: auto;
  margin: 9px;
}

.uploadedPic{
  width: 50%;
  height: auto;
  margin: auto;
  margin-left: 28px;
  /* transform: rotate(20deg); */
}

.modal {
  font-size: 12pt;
  font-weight: 400;
  overflow-x: hidden;
}

.noscrollX {
  overflow-x: hidden;
}

.noscrollY {
  overflow-y: hidden;
}

.desktopProfilePic {
  border-radius: 15px;
  /* width: 100%; */
  /* max-height: 400px; */
  max-width: 100%;
  max-height: 360px; 
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  margin-top: -25px;
  margin-bottom: 5px;
  /* margin-left: 10px; */
  border-style: solid;
  border-width: thin;
  border-color: rgb(36, 36, 36);
  display: inline-block;
}

.mobileProfilePic {
  border-radius: 15px;
  /* width: 100%; */
  max-width: 100%;
  max-height: 260px; 
  box-shadow: 2px 2px 5px rgb(202, 202, 202);
  margin-top: -23px;
  margin-bottom: 16px;
  /* margin-left: 10px; */
  border-style: solid;
  border-width: thin;
  border-color: rgb(36, 36, 36);
  display: inline-block;
}

.blankPic {
  border-radius: 15px;
  width: 200px;
  /* box-shadow: 2px 2px 5px rgb(179, 179, 179); */
  /* margin-top: 5px; */
  /* margin-bottom: 50px; */
  margin-left: 3px;
  cursor: pointer;
  margin-bottom: 14px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(75, 75, 75);
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgb(219, 219, 219);
}

.blankPicSmall {
  border-radius: 15px;
  width: 160px;
  /* box-shadow: 2px 2px 5px rgb(179, 179, 179); */
  margin-top: 6px;
  /* margin-bottom: 47px; */
  /* margin-left: 10px; */
  cursor: pointer;
  margin-bottom: 12px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(75, 75, 75);
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgb(219, 219, 219);
}

.heading3 {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  /* margin-bottom: 2px; */
  letter-spacing: 3.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 44pt;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: -2px;
  /* background-color: pink; */
  /* margin-top: -10px; */
  /* padding-top: 15px; */
}

.messagesHeading {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 2.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 32pt;
  display: inline-block;
  margin-bottom: 16px;
  margin-top: 6px;
}

.messagesWithHeading{
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 1.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 22pt;
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 2px;
  line-height: 34px;
}

.searchHeading {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 2.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 32pt;
  display: block;
  margin-bottom: 10px;
  margin-top: 6px;
}

.accountHeading {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 2.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 32pt;
  display: block;
  margin-bottom: 6px;
  margin-top: 6px;
}

.nowHeading {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 2.3px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 30pt;
  display: block;
  margin-top: 4px;
  margin-bottom: -14px;
}

.postHeading {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 1.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 26pt;
  display: block;
  line-height: 38px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.postContent {
  font-size: 14pt;
  font-weight: 400;
  white-space: pre-wrap;
}

.postAuthor {
  font-size: 12pt;
  font-weight: 400;
  font-style: italic;
}
.postDate {
  font-size: 12pt;
  font-weight: 400;
  margin-bottom: 18px;
  font-style: italic;
  
}

.messageRow {
  max-width: 550px;
  width: 85%;
  margin-left: 14px;
  background-color: white;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgb(204, 204, 204);
  padding: 12px;
  padding-left: 22px;
  cursor: pointer;
  margin-bottom: 12px;
}

.messageRowSmall {
  width: 100%;
  background-color: white;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgb(204, 204, 204);
  padding: 8px;
  padding-left: 18px;
  cursor: pointer;
  margin-bottom: 10px;
}

.userHeading{
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 3.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 44pt;
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  line-height: 65px;
  margin-top: 15px;
}

.smallerProfileHeading {
  font-size: 30pt;
  letter-spacing: 2px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: -2px;
}

.profileHeadingLarge {
  font-size: 13pt;
  font-weight: 700;
}

.profileHeadingSmall {
  font-size: 13pt;
  font-weight: 700;
}


.profileLarge{
  font-size: 17pt;
  margin-left: 4px;
  
}

.profileSmall{
  font-size: 15pt;
}


.editIcon {
  width: 22px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 1px;
  margin-top: 10px;
  position: relative;
  top: -4px;
  width: 22px;
}

.editIconName {
  position: relative;
  top: -6px;
  width: 22px;
}

.iconNameSmall {
  position: relative;
  top: -1px;
  width: 22px;
}


.checkIcon{
  width: 27px;
  cursor: pointer;
  margin-left: 10px;
  top: 2px;
  position: relative;
}

.checkIconAbout{
  width: 27px;
  cursor: pointer;
  margin-left: 10px;
  bottom: 30px;
  position: relative;
}

/* 
.editIconHeading{
  width: 17px;
  cursor: pointer;
  margin-left: 8px;
} */

.profileText{
  margin-bottom: 18px;
  margin-top: 1px;
}

.closeIcon {
  width: 26px;
  /* margin: 0px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  left: 0px;
  top: 0px;
  padding: 0px; */
  position: relative;
  margin-top: 0px;
  cursor: pointer;
  z-index: 500;
}


.pleaseWait {
  margin: 50px;
  margin-left: 4px;
  color: var(--main-red);
  background-color: white; 
  padding: 25px;
  font-size: 16pt; 
  display: inline-block; 
  border-style: solid;
  border-width: 1.5px;
  border-color: rgb(36, 36, 36);
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  animation-name: example;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  /* animation-direction: alternate; */
}


@keyframes example {
  from {color: #cc3d27;}
  to {color: #0c4c88;}
}

.invisible {
  visibility: hidden;
}

.desktopUserPic {
  border-radius: 15px;
  max-width: 100%;
  max-height: 360px; 
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  /* margin-top: -25px; */
  margin-bottom: 5px;
  border-style: solid;
  border-width: thin;
  border-color: rgb(36, 36, 36);
  display: inline-block;
}

.messageLabel {
  font-size: 17pt;
  font-weight: 500;
  margin-left: 17px;
  display: inline-block;
  margin-bottom: 3px;
}

.chatEntry {
  font-size: 14pt;
  font-weight: 400;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  border-color: rgb(201, 201, 201);
  padding: 11px;
  padding-top: 9px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13.5pt;
  letter-spacing: .4px;
  box-shadow: 2px 2px 5px lightgrey;
  line-height: normal;
  font-weight: 400;
  margin-top: 14px;
  margin-top: 14px;
  width: 86%;
  max-width: 700px;
  line-height: 25px;
  white-space: pre-wrap;
}

.yourChat {
  background-color: #f1f8ff;
}

.theirChat {
  background-color: #fff4f4;
  margin-left: 25px;
}

.dateSent {
  color: rgb(146, 146, 146);
  font-size: 9.5pt;
  font-weight: 500;
  margin-top: -4px;
  margin-bottom: -2px;
}

.searchSelected {
  font-size: 12.5pt;
  font-weight: 400;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 4px;
  max-width: 800px;
}



#newMail {
  width: 22px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  box-shadow: 2px 2px 5px rgb(190, 190, 190);

}

#newMailSmall {
  width: 18px;
  margin-right: 3px;
  display: inline-block;
  position: relative;
  box-shadow: 2px 2px 5px rgb(190, 190, 190);
}


.logoContainer {
  padding-left: 25px;
  padding-top: 7px;
}

.userPic {
  max-width: 100%;
  max-height: 190px;
  
}

.searchResult {
  height: 200px;
  font-size: 14pt;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgb(219, 219, 219);
  /* margin-top: 12px; */
  margin-bottom: 10px;
  max-width: 740px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
  background-color: #e6e6e6;
  color: rgb(24, 24, 24);
}

.searchItemLeft {
  grid-column: 1 / 2;
  margin: auto;
  /* background-color: lightblue; */
  /* padding: 5px; */
  /* background-image: url("paper.gif"); */
  
  height: 200px;
  
}

.searchItemRight {
  grid-column: 2 / 2;
  text-align: left;
  padding: 6px 15px 6px 15px;
  background-color: #ffffff;
  border-radius: 0px 5px 5px 0px;
  
}

.searchResultHeading {
  text-shadow: 3px 3px 5px rgb(219, 219, 219);
  letter-spacing: 2.5px;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  font-size: 22pt;
  display: block;
  margin-bottom: -3px;
  overflow: hidden;
  height: 42px;
}

.searchResultAbout {
  overflow: hidden;
  height: 147px;
  font-size: 12pt;
}

.postRow {
  background-color: white;
  margin-bottom: 6px;
  max-height: 64px;
  font-size: 12.5pt;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgb(219, 219, 219);
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowSelectedCity {
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* height: 70px; */
}


.nowSelectButton {
  height: 35px;
  background-color: #0c3963;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-family: 'Palanquin', sans-serif;
  font-weight: 500;
  padding: 8px 12px 10px 12px;
  letter-spacing: 0.4px;
  word-spacing: 1px;
  /* margin-bottom: 6px; */
  margin-left: 10px;
  margin-right: 14px;
  /* margin-top: 7px; */
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  box-shadow: 2px 2px 5px rgb(179, 179, 179);
  font-size: 10.5pt;
  /* float: left; */
}

.nowOverflow {
  /* text-overflow: ellipsis; */
  overflow: hidden;
  font-size: 19.5pt;
}

.regionHeader {
  cursor: pointer;
}

.unfoldedImg {
  transform: rotate(90deg);
}

.appleAppIcon {
  width: 50px;
  margin-top: 32px;
  margin-right: 45px;
  cursor: pointer;
}

.androidAppIcon {
  width: 49px;
  margin-top: 32px;
  margin-left: 45px;
  cursor: pointer;
}

.appleAppIconLoggedIn {
  width: 50px;
  margin-top: -15px;
  margin-right: 45px;
  cursor: pointer;
}

.androidAppIconLoggedIn {
  width: 49px;
  margin-top: -15px;
  margin-left: 45px;
  cursor: pointer;
}

input::-ms-input-placeholder{color: black;}
textarea::-ms-input-placeholder{color: black;}